import { extend } from 'lodash'
import { useState } from 'react'
import { notifyError } from 'app/services/notify.client'
import { debug, logError } from 'lib/logging'

type RequestOptions = Omit<RequestInit, `body`> & { body: any }

/**
 * make a request via window.fetch and return the response. all requests sent as JSON
 *
 * @returns request
 * @throws http responses with a status code >= 400
 */
export function useRequest<T>(showErrors = true) {
  const [inFlight, setInFlight] = useState(false)

  const request = async (url: string, opts?: RequestOptions) => {
    let options: RequestInit = {
      method: `GET`,
      ...opts,
      headers: {
        'Content-Type': `application/json`,
        ...opts?.headers,
      },
    }
    debug(`contact`, `request`, url, options)
    if (opts?.body && options.method === `GET`) {
      url += `?${new URLSearchParams(opts.body).toString()}`
      options.body = undefined
      debug(`contact`, `request`, `GET with body`, url, options)
    }
    if (typeof options?.body === `object`) {
      options.body = JSON.stringify(options.body)
    }

    setInFlight(true)
    let resp = await window.fetch(url, options)
    setInFlight(false)
    if (resp.headers.get(`content-type`)?.match(`application/json`)) {
      let data: T = await resp.json()
      if (resp.status >= 400) {
        logError(`Error response from server`, { status: resp.status, data })
        if (showErrors && resp.status !== 401)
          notifyError(`There was a problem loading data from the server. You may need to reload the page.`)
      }
      return data
    }
  }

  return extend(request, { inFlight: inFlight })
}
